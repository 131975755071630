.slick-arrow {
    background-color: black;
    color: black;
}
.slick {
    max-width: 100%;
    justify-content: center;
    text-align: center;
}
.heading {
    margin-bottom: 4px;
}
.bg-color {
    background-color: var(--pb-blue);
    color: whitesmoke;
}
.tagone {
    color: var(--text-gray);
}

.bg-grey {
    background-color: var(--text-lightgray);
}
.slider {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    justify-content: center;
}
.card-c {
    width: 18rem;
    height: 32rem;
}
.card-d {
    width: 18rem;
}
.bg-body {
    background-color: black;
    border-bottom: 1px;
}
.bg-main {
    background-color: #f1f4f9;
}
.bg-contact {
    background-image: linear-gradient(#7474bf, #348ac7);
}
.bg-white {
    background-color: white;
}
.text-grey {
    color: var(--text-gray);
}
.content {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.margin {
    margin-left: 95px;
}
.bg-color {
    background-color: #333b47;
}
.border {
    color: black;
}
.card:hover {
}
.hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.29);
}
.only-hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.29);
}
.hover:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.59);
}
.card-style {
    border-color: rgb(167, 160, 160);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}
.bg {
    background-color: azure;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: '';
}
.image {
    background-image: url('../assets/encrypt.jpg');
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.image:before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: '';
    background-color: rgba(0, 0, 0, 0.7);
}
.image .container {
    position: relative;
    z-index: 2;
}
.color {
    color: #f1f4f9;
}
/* .sign-up{
    background-image: linear-gradient(#7474bf, #348ac7);
    width: 100px;
    height: 35px;
    border-radius: 30px;
    border: none;
    color: rgb(255, 255, 255);
} */
  .sign-up {
    width: 200px;
    height: 35px;
    border-radius: 30px;
     /* color: #1055A1;
    border-color: #1055A1;  */
     background-color: #dde0e5;
    border: none;
    color: black;
} 
.sign-in {
    background-image: linear-gradient(#7474bf, #348ac7);
    width: 100px;
    height: 35px;
    border-radius: 30px;
    border: none;
    color: rgb(255, 255, 255);
}
.login{
    background-image: linear-gradient(#7474bf, #348ac7);
    width: 100px;
    height: 35px;
    border-radius: 30px;
    border: none;
    color: rgb(255, 255, 255);
    display: none;
}
/* .bg-nav{
    background-color: #ffffff;
} */

.request-demo {
    width: 150px;
    height: 50px;
    border-radius: 30px;
    background-color: #1055a1;
    color: white;
}

.features-title {
    color: #00b1ff;
    margin-top: 30px;
}
.bg-trusted {
    background-color: #eceff1;
}
.bg-login {
    background-color: beige;
}
.max-ht {
    max-height: 80px;
    min-height: 80px;
}
.hero-color {
    color: rgb(60, 57, 57);
}
.opacity {
    opacity: 0.5;
}
.md-18 {
    font-size: 20px !important;
}
.line {
    width: 120px;
    border: solid 2px;
}
.card-width {
    /* min-height: 18rem; */
    /* max-width: 22rem; ///////*/
    max-width: 18rem;
    /* max-height: 200rem; */
    /* min-height: 100rem; */
    height: 500px;
    /* height: 500px;////////// */
    border-radius: 10px;
    width: auto;
}
.card-widthh {
    /* min-height: 18rem; */
    max-width: 22rem;
    /* max-width: 18rem; */
    /* max-height: 200rem; */
    /* min-height: 100rem; */
    /* height: 500px; */
    height: 500px;
    border-radius: 10px;
    width: auto;
}
.slider-card-width {
    min-width: 10rem;
    max-width: 10rem;
    max-height: 10rem;
    min-height: 10rem;
}
.img-ht {
    max-width: 250px;
    max-height: 250px;
}
.img-ht-2 {
    max-width: 150px;
    max-height: 150px;
}
.card-wo-border {
    border: none;
    background-color: #f8f9fa;
}
.max-height {
    max-height: 300px;
}
.btn-min-width {
    min-width: 100px;
}
.max-width {
    max-width: 400px;
    min-height: 300px;
    margin-top: 100px;
}
.btn-width {
    min-width: 60px;
}
.bor {
    padding: 100px;
    border: 1px solid black;
    border-radius: 7px;
}
.rbor {
    padding: 0px;
    border: 1px solid rgb(244, 223, 223);
    border-radius: 7px;
    margin-left: 20px;
}
/* ///////////////////// */

.wp-caption {
    position: relative;
    padding: 0;
    margin: 0;
}
.wp-caption img {
    display: block;
    max-width: 100%;
    height: auto;
}
.wp-caption:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0)
        linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0px,
            rgba(0, 0, 0, 0.6) 100%
        )
        repeat 0 0;
    z-index: 1;
}
.wp-caption-text {
    display: block;
    position: absolute;
    width: 100%;
    color: #ffffff;
    left: 0;
    bottom: 0;
    padding: 1em;
    font-weight: 700;
    z-index: 2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.left {
    background-image: linear-gradient(#7474bf, #348ac7);
    width: 100px;
    height: 35px;
    border-radius: 30px;
    border: none;
    color: rgb(255, 255, 255);
}

.cardb {
    background: #ddd;
}
.cardd {
    box-shadow: 0 0 30px rgb(0, 0, 0, 0.5);
    overflow: hidden;
    border-radius: 15px;
    padding-top: 12px;
}
/* ///////////////////////// */
#badge {
    box-sizing: border-box;
    color: #0e69f2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    border: 1px solid #0f68e6;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 8px;
}
.cont {
    background-image: linear-gradient(#7474bf, #348ac7);
}
.navbg {
    background-color: white !important;
}
.arrow {
    margin-top: 150px;
}
.im {
    width: 300;
    height: 236;
}
.flo {
    float: right;
}

#messageContainer {
    display: block;
    margin: 30 auto;
    margin-top: 10px;
}

.password-input-container {
    position: relative;
}

.eye-icon {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust this value to position the eye icon */
    transform: translateY(-50%);
    cursor: pointer;
}
.password-input-container {
    position: relative;
}

.password-icon {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust this value to position the eye icon */
    transform: translateY(-50%);
    cursor: pointer;
}

.text-red {
    color: red;
}

@media (max-width: 650) {
   
   .sign-in{
    display: none;

   }
   .sign-up{
    display: none;
   }
   .login{
    display: inline-block;
    width: 100%;
   }
}
