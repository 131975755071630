@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Light.eot');
    src: url('HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
        url('HelveticaNeue-Light.woff2') format('woff2'),
        url('HelveticaNeue-Light.woff') format('woff'),
        url('HelveticaNeue-Light.ttf') format('truetype'),
        url('HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Bold.eot');
    src: url('HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
        url('HelveticaNeue-Bold.woff2') format('woff2'),
        url('HelveticaNeue-Bold.woff') format('woff'),
        url('HelveticaNeue-Bold.ttf') format('truetype'),
        url('HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Medium.eot');
    src: url('HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('HelveticaNeue-Medium.woff2') format('woff2'),
        url('HelveticaNeue-Medium.woff') format('woff'),
        url('HelveticaNeue-Medium.ttf') format('truetype'),
        url('HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

