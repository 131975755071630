:root{
    --theme-blue : #0c2840;
    --text-gray : #6e6868;
    --theme-pb-blue:#1b1e2e;    
    --text-black: #1D1D1F;
    --theme-lightblue: #1055A1;
    --theme-gold: #bb915a;
    --theme-light-gold: #ffc880;
    --blue: #1A6EFF;
    --purpul: #FF0087;
    --orange: #EE5100;
    --light-blue: #00B1FF;
    --green: #00B900;
    --red: #EC0000;
    --pb-blue: #333b47;
    --text-lightgray: #f2f2f2;
}
.form-val{
    color: var(--red);
}
.btn-default{
    background-color: var(--theme-pb-blue)!important ; 
    color: white!important;
}
.semi-bold{
    font-weight: 700;
}
.font-light{
    font-weight: 400;
}
.gray-font{
    color: var(--text-gray);
}

.index{
    z-index: 1;
}

body {
    font-size: 15px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: var(--text-black);
}

.fw-bold {
    font-weight: bold!important;
}

.fw-midium {
    font-weight: 500!important;
}

.fw-light {
    font-weight: 300!important;
}

.material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48
}

.md-14 {
    font-size: 14px!important;
}

.md-18 {
    font-size: 18px!important;
}

.md-24 {
    font-size: 24px!important;
}

.md-28 {
    font-size: 28px!important;
}

.md-32 {
    font-size: 32px!important;
}

.md-40 {
    font-size: 40px!important;
}

.md-48 {
    font-size: 48px!important;
}

.md-56 {
    font-size: 56px!important;
}

.md-68 {
    font-size: 68px!important;
}

.md-72 {
    font-size: 72px!important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

h1,
.h1 {
    font-size: 36px!important
}

h2,
.h2 {
    font-size: 28px!important
}

h3,
.h3 {
    font-size: 22px!important
}

h4,
.h4 {
    font-size: 18px!important
}

h5,
.h5 {
    font-size: 16px!important
}

h6,
.h6 {
    font-size: 14px!important;
}
a{
    text-decoration: none;
}

a,
a:hover,
.btn-link {
    /*color: var(--theme-gold);*/
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}

div *:last-child {
    margin-bottom: 0;
}

.c-gold {
    color: var(--theme-gold);
}

.navbar-brand:hover {
    text-decoration: none;
    color: var(--theme-gold);
}

.sidebarbtn {
    border-radius: 0px;
}

.sidebarbtn:hover,
.sidebarbtn:active,
.sidebarbtn.active {
    border-right:50px!important;
    /*background-color: white!important;*/
    color: #9aa2fc!important;
}

.text-blue {
    color: var(--blue)!important;
}

.text-orange {
    color: var(--orange)!important;
}

.text-purpul {
    color: var(--purpul)!important;
}

.text-green {
    color: var(--green)!important;
}

.text-light-blue {
    color: var(--light-blue)!important;
}

.text-red {
    color: var(--red)!important;
}

.text-gold {
    color: var(--theme-gold)!important;
}

.bg-gold {
    background-color: var(--theme-gold)!important;
}

.cdk-overlay-container {
    z-index: 1900;
}

.cdk-overlay-backdrop {
    backdrop-filter: blur(10px);
}

.stepbtn {
    /* margin-bottom: 30px; */
    border: none;
    background-color: #fff;
    outline: none;
}

.stepbtn:focus {
    outline: none;
}

.tabbtn-line {
    position: relative;
}

.tabbtn-line:before {
    content: "";
    position: absolute;
    left: 18px;
    top: 8px;
    bottom: 40px;
    width: 1px;
    border-left: dashed 1px #ccc;
    z-index: 0;
}

.tabbtn-line button {
    position: relative;
    z-index: 2;
    background-color: transparent!important;
}

.tabbtn-line button.active .btn-title {
    color: var(--theme-gold);
}

.tabbtn-line button .material-symbols-outlined {
    background-color: #fff!important;
}

.mat-horizontal-content-container {
    padding-left: 0!important;
    padding-right: 0!important;
}

.mat-mdc-form-field {
    font-size: 14px;
}

.mat-mdc-text-field-wrapper,
.mat-mdc-form-field-infix {
    height: 46px!important;
}

.mat-mdc-form-field-infix {
    padding-top: 12px!important;
    padding-bottom: 12px!important;
}

.mat-mdc-form-field .mat-mdc-icon-button svg {
    width: 24px;
    height: 24px;
}

.img-cover {
    object-fit: cover!important;
}

.img-contain {
    object-fit: contain!important;
}

.w-100px {
    width: 100px;
}

.sign-up {
    width: 150px;
    height: 35px;
    border-radius: 30px;
    color: #1055A1;
    border-color: #1055A1;
}

.request-demo {
    width: 150px;
    height: 35px;
    border-radius: 30px;
    background-color: #1055A1;
    color: white;
}

.features-title {
    color: #00B1FF;
    margin-top: 30px;
}
.sidebarht {
    background-color: #1b1e2e;
    /* flex:1 1 auto; */
}



