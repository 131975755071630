.icnbg {
    border-bottom: solid 1px var(--bs-border-color);
    margin-right: 0;
    
}
.background{
    background-color: #f2f5f9;
}
.img-bg{
    background-color: #1b1e2e;
}
.image{
    margin-left: 45px;
}

.navheight {
    height: 52px;
}
button.navbtn{
    background-color: #f2f5f9;
    margin-top: 1px;
}
.navbtn {
    margin-top: 1px;
    border: none;
    background-color: #f2f5f9;
}

.sidebarht {
    /* height: calc(100vh - 52px); */
    min-height: 670px;
    background-color: #1b1e2e;
    flex:1 1 auto;
    /* width: auto;
    position: fixed;
    top: 52px;
    width: 100%;
    max-width: 220px;
    overflow-y: auto; */
}

.sidebarfont {
    font-size: 16px;

    /* font-family: 'helvetica_neuebold', Arial, Helvetica, sans-serif; */
    color:#9095ad;
}

.sidebarbtn {
    text-align: left;
    width: 100%;
    border: none;
    color: #9095ad;
}

.colours {
    background-color: rgb(201, 169, 171);
}

.example-container {
    width: 500px;
    height: 300px;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.example-sidenav-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.example-sidenav {
    padding: 20px;
}

.sidebar {
    max-width: 220px;
    position: sticky;
    top: 52px;
}

.icon {
    max-width: 220px;
    
}

.logoutmarginright{
    margin-right: 25px;
}