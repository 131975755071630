/* body{
    margin-top:20px;
    color: #1a202c;
    text-align: left;
     background-color: #e2e8f0;     
} */
/* .main-body {
    padding: 15px;
} */
.profile-card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.profile-card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.profile-container {
    margin-left: 200px;
    justify-content: center;
    align-items: center;
    align-items: center;
  }

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.mb-3, .my-3 {
    margin-bottom: 2rem!important;
}

.pull-right {
    text-align: right;
}

