.slider{
    margin-right: 280px;
    margin-top: 15px;
}
.active-box{
    margin-top: 38px;
    margin-left: 15px;
}
.formName{
    border-bottom: 5px;
}
.search-btn{
    margin-top: 30px;
}
.card-header{
    border: none;
}
.card{
    border: none;
}
.card-header-bg{
    background-color: white;
}
